.room-wrapper-main-room {
      display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
    min-height: 84vh;
    justify-content: flex-start;
    background-color: #161a1d;
    padding-bottom: 50px;
}

.all-messages p {
  font-size: 17px;
  color: #fcfafa;
  font-weight: 500;
  letter-spacing: 0.03rem;
  font-family: sans-serif;
}

.message-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  word-wrap: break-word;
  min-width: 130px;
  padding: 4px;
  margin: 0;
}

.message-timestamp-left {
  display: none;
  opacity: 0%;
  transition: transform 1s, opacity 3s;
}

.message-timestamp-left > p {
  color: white;
}

.message-timestamp-right > p {
  color: white;
}

.message-timestamp-right {
  opacity: 0%;
  display: none;
  transition: transform 0.5s, display 4s;
  z-index: 1;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 10px;
}

.container:hover > .message-timestamp-left {
  animation: appearFromRight .4s ease-in-out;
  display: flex;
  opacity: 100%;
  position: absolute;
  top: 20%;
  bottom: -20%;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 0.85em;
  font-weight: bold;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition:opacity 2s ease;
}
@keyframes appearFromRight {
  0%{
    transform:translateX(200%)
  }
  100%{
    transform:translateX(0)
  }
}
@keyframes appear {
  0%{
    transform:translateX(-200%)
  }
  100%{
    transform:translateX(-27%)
  }
}

.container:hover .message-timestamp-right {
   animation: appear .4s ease-in-out;
  position: absolute;
  display: flex;
  opacity: 100%;
  font-size: 0.85em;
  font-weight: bold;
  left: 5%;
  top: 35%;
  width: -moz-fit-content;
  width: fit-content;
}

.message-blue {
  animation: slideFromTop 1s ease-out 0s 1;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: #147efb;
  max-width: 359px;
  width: auto;
  text-align: left;
  font: 500 0.9em "Open Sans", sans-serif;
  border: 1px solid #1d1acb00;
  border-radius: 30px;
  transition: transform 0.4s;
  z-index: 50;
}
@keyframes slideFromTop {
  0% {
    transform: rotateX(180deg);
  }
  25% {
    transform: translateX(-100%);
  }
  30% {
    transform: translateX(0);
  }
  40% {
    transform: rotateX(45deg);
  }
  50% {
    transform: translateX(-50%);
  }
  60% {
    transform: translateX(-45%);
  }
  70% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(-28%);
  }
  90% {
    transform: translateX(0);
  }
  95% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

.message-content > p {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.message-green {
  animation: slideFromTop 1s ease-out 0s 1;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  padding: 10px;
  background-color: #53d769;
  width: -moz-fit-content;
  max-width: 75%;
  max-height: auto;
  height: auto;
  text-align: left;
  font: 400 0.9em "Open Sans", sans-serif;
  border: 1px solid #97c6e3;
  border-radius: 10px;
  transition: transform 0.4s;
  z-index: 50;
}
@keyframes slideFromTop {
  0% {
    transform: rotateX(180deg);
  }
  25% {
    transform: translateY(-100%);
  }
  30% {
    transform: translateX(0);
  }
  40% {
    transform: rotateX(45deg);
  }
  50% {
    transform: translateY(-50%);
  }
  60% {
    transform: translateY(-45%);
  }
  70% {
    transform: translateX(0);
  }
  80% {
    transform: translateY(-28%);
  }
  90% {
    transform: translateX(0);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

.message-green .message-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  word-wrap: break-word;
  min-width: 151px;
  padding: 4px;
  margin: 0;
  color: #fcfafa;
  font-weight: 500;
  letter-spacing: 0.03rem;
  font-family: sans-serif;
}

.message-blue:after {
  content: "";
  position: absolute;
  border-top: 10px solid #147efb;
  border-left: 50px solid transparent;
  border-right: 15px solid transparent;
  top: 0;
}

.message-blue:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 0;
  border-top: 30px solid #147efb;
  border-left: 25px solid transparent;
  border-right: 15px solid transparent;
  top: -1px;
  left: -15px;
}

.message-green:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid #53d769;
  border-left: 5px solid #53d769;
  border-right: 25px solid transparent;
  top: 40px;
  left: -1px;
}

.message-green:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 28px solid #53d769;
  border-left: 25px solid transparent;
  border-right: 15px solid transparent;
  top: 0px;
  left: 25px;
}

.container:hover > .message-blue {
  transform: translateX(-100px);
}

.container.blue:hover .user {
  transform: translateX(-100px); /* Add this rule */
}

.container.green:hover .user.green {
  transform: translateX(15px); /* Add this rule */
}
.user.green {
  transition: transform 0.3s ease-in-out;
}
.container:hover > .message-green {
  transform: translateX(100px);
}
.container:hover .message-timestamp-right {
  transform: translateX(-25%);
}
.container.blue {
  position: relative;
  display: flex;
  justify-self: flex-end;
  width: auto;
  justify-content: flex-end;
  align-items: flex-end;
}

.container.green {
     position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    padding-bottom: 51px;
      padding-left: 20%;
}

.container.green .user.green {
  position: absolute;
  bottom: -23px;
  left: 8px;
  right: 100%;
  font-size: 0.85em;
  font-weight: 700;
  color: white;
  width: -moz-fit-content;
  width: auto;
  transition: transform 0.5s ease-in-out;
  z-index: 2;
}
.room_name {
  position: sticky;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 25px 5px 25px;
  height: 2rem;
  align-items: center;
  align-self: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1),
    /* Slight white tint */ 0 8px 16px rgba(255, 255, 255, 0.1),
    /* Slight white tint */ 0 12px 24px rgba(0, 0, 0, 0.2);
  border-bottom: 3px outset #2e353b;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  z-index: 60;
  top: 0px;
  opacity: 90%;
  background-color: black;
}
.room_name > h2 {
  color: white;
}
.message-content {
  z-index: 5;
  font-family: "Inter", sans-serif;
  font-family: "Varela Round", sans-serif;
}
.container.blue > .user {
  display: flex;
  position: relative;
  font-size: 0.85em;
  font-weight: 700;
  color: white;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 25px;
  top: 5px;
  transition: opacity 1, transform 1s;
  z-index: 50;
}
.user {
  transition: transform 0.5s ease-in-out;

  transition: opacity 1, transform 0.3s ease;
  z-index: 50;
}
.user {
  transition: transform 0.4s ease;
}
.messagesContainer {
  position: relative;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 25px;
  background-color: transparent;

  padding: 4px;
}

.all-messages {
    position: relative;
    display: flex;
    flex-direction: column;
    /* width: 55vw; */
    max-height: 75vh;
    min-width: 550px;
        
        min-height: 75vh;
    overflow-y: scroll;
    overflow-X:hidden ;
    scroll-behavior: smooth;
    background: linear-gradient( 135deg, #161a1d 0%, #283644 25%, #1e252b 50%, #161a1d 100% );
    background-size: 200% 200%;
    animation: gradientBackground 16s ease infinite;
    margin: 15px 15px;
    padding: 0px 15px 30px;
    z-index: 1;
    border-radius: 16px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2), 0 10px 24px rgba(0, 0, 0, 0.2), 0 16px 40px rgba(0, 0, 0, 0.2), inset 0 -4px 12px #0d1115, inset 0 4px 8px #1c2125;
    box-sizing: border-box;
    perspective: 600px;
}
@keyframes gradientBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.all-messages::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

h1 {
  color: white;
}
.send-input-wrapper {
    position: relative;
    min-width: 500px;
    max-width: 23vw;
    display: flex;
    gap: 10px;
}
#send_message_input {
  position: relative;
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  border-radius: 5px;
}
#send_message_input::placeholder {
  padding-left: 10px;
}
.sendMsgBtn {
  position: relative;
  width: 100px;
  min-height: 42px;
  max-height: 42px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  border: 1px solid rgb(12, 144, 237);
  border-radius: 10px;
  overflow: hidden;
  color: white;
  font-weight: 600;
  padding: 3px 10px;

  transition: transform 0.3s ease-in-out;
}
.sendMsgBtn:hover {
  background-position: 100% 100%;
  border-color: #03fd42;
  transform: scale(1.05);
}

.clicked {
  animation: slideAway 0.8s ease-out 0s 1;
  justify-self: center;
}
@keyframes slideAway {
  0% {
    transform: translateX(0) rotateX(0);
  }
  25% {
    transform: translateX(100%) rotateX(80deg);
  }
  40% {
    transform: translateX(200%);
  }
  42% {
    height: 0px;
    width: 0px;
  }
  60% {
    transform: translateX(-200%);
  }
  90% {
    transform: rotateX(10deg) translate(33%);
  }
  100% {
    transform: translateX(0) rotateY(0);
  }
}
.helper_tools_wrapper {
  position: relative;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;
}
#leave-room-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 5px;
  background-color: transparent;
  border: 2px solid rgb(12, 73, 116);
  border-radius: 10px;
  color: white;
  font-weight: 600;
  padding: 3px 15px;
  min-width: 150px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  justify-content: center;
  z-index: 3;
}
#leave-room-btn:hover {
  border-color: #32c056;
  background-position: 100% 100%;
  transform: scale(1.05);
}
#leave-room-btn:hover > #back-button {
  color: #03fd42;
}
#back-button {
  color: #32c056;
}
#back-button:hover {
  color: #1bef50;
}
.subscribe_to_room {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 5px;
  background-color: transparent;
  border: 2px solid rgb(12, 73, 116);
  border-radius: 10px;
  color: white;
  font-weight: 600;
  padding: 3px 15px;
  min-width: 150px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  justify-content: center;
  z-index: 3;
}
.subscribe_to_room:hover {
  background-position: 100% 100%;
  border-color: #32c056;
  transform: scale(1.05);
  border-width: 1px;
}
.subscribe_to_room:hover > #subscribe_btn {
  color: rgb(12, 237, 87);
}
#subscribe_btn {
  animation: slideAway 0.8s ease-out 0s 1;
  color: rgba(12, 237, 87, 0.63);
}
.subscribe_to_room._joined {
  border: 2px solid rgb(12, 73, 116);
  box-sizing: border-box;
  width: fit-content;
}
.subscribe_to_room._joined:hover {
  border-color: red;
}

#unsubscribe_icon {
  animation: slideAway 0.8s ease-out 0s 1;
  color: red;
}
.leave-delete-button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.leave-delete-button button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  font-size: 10px;
  line-height: 1;
  border-radius: 500px;
  transition-property: background-color, border-color, color, box-shadow, filter;
  transition-duration: 0.3s;
  border: 1px solid transparent;
  letter-spacing: 2px;
  max-width: 100px;
  text-transform: uppercase;
  white-space: normal;
  font-weight: 700;
  text-align: center;
  padding: 10px 20px;
  color: black;
  height: 10px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.room-num-input-mainRoom {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 35%;
}

.room-num-input-mainRoom > input {
  width: 100%;
  background-color: black;

  color: white;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.room-num-input-mainRoom > input::placeholder {
  color: white;
  padding-left: 5px;
}

.status_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.statusBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
  border: none;
  height: 20px;
  width: fit-content;
  padding: 15px;
  color: rgb(20, 242, 87);
  font-family: sans-serif;
  border-radius: 8px;
}
.user-profile-pic {
  position: absolute;
  left: -105px;
  top: -3%;
  border: 4px solid black;
  border-left-width: 2px;
  border-radius: 15px;
  max-width: 4.5rem;
  min-width: 4.5rem;
  max-height: 4.5rem;
  min-height: 4.5rem;
  box-sizing: border-box;
}
.user-profile-pic:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.user-profile-pic.green {
  left: -95px;
}

@media only screen and (max-width: 600px) {
  html {
    width: 100%;
  }

  .all-messages {
     display: flex;
    width: -1%;
    min-height: 64vh;
    max-height: 67vh;
    min-width: 92vw;
    /* max-width: 104vw; */
    justify-self: flex-end;
    padding: 0px 10px 10px 10px;
  }

  .message-green {
    max-width: 116%;
  }

  .container.green {
    display: flex;
    align-items: flex-start;
    width: 45%;
    margin-left: 3%;
    padding-bottom: 14px;
  }

  .container.blue {
    display: flex;
    align-items: flex-start;
    width: 65%;
    margin-left: 20%;
  }

  .message-blue {
    left: 9%;
    flex-wrap: wrap;
    width: 87%;
  }

  .container:hover .message-green {
    transform: translateX(100px);
  }

  .container:hover .message-blue {
    transform: translateX(-100px);
  }

  .container:hover .message-timestamp-left {
    position: absolute;
    right: 0;
  }

  .container:hover .message-timestamp-right {
    position: absolute;
    left: -1%;
  }

  .user-profile-pic {
    position: absolute;
    display: flex;
    width: 3rem;
    height: 3rem;
    padding: 0px;

    margin-left: 9px;
  }
  .container.blue > .user {
    left: 38px;
    top: 6px;
  }
  .roomWrapper {
    border-radius: 25px;
    padding: 10px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    width: 100vw;
  }
  .room-wrapper {
    height: 69vh;
  }
  .room-num-input-mainRoom {
    flex-direction: row;
    margin-bottom: 15px;
    width: 75%;
  }

  .room-num-input-mainRoom input {
    width: 100%;
  }

  .sendMsgBtn {
    height: fit-content;
    padding: 5px 35px 5px 10px;
  }

  .leave-delete-button {
    padding-top: 0px;
  }
  .send-input-wrapper {
    min-width: 81vw;
  }
  .leave-delete-button button {
    bottom: -60px;
  }

  .room_name > h2 {
    font-size: 17px;
    align-self: center;
  }

  .status_container {
    position: relative;
    display: flex;
  }
}
