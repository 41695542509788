
.App {
  text-align: center;
  height: 100vh;
  width:100vw;
}


.App-body {
  position: relative;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0b090a;
  
  display: flex;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overscroll-behavior-x:none ;

}
.App::-webkit-scrollbar{
  display:none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
