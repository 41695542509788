.private_room_main_wrapper{
    position: relative;
    display:flex;
    flex-direction: column;
    max-width:80%;
    min-width:75%;
    
    align-self: center;
    justify-self: center;
    text-align: center;
    padding: 10px;
       top: 139px;
        border: 2px solid #1c2125;
    border-top: 4px inset #1f262d;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 4px 8px rgba(22, 108, 150, 0.3);
    background: linear-gradient( 135deg, #161a1d 0%, #283644 25%, #1e252b 50%, #161a1d 100% );
    animation: RoomsCreated_RoomsCreated_RoomsCreated_RoomsCreated_gradientBackground__CW4HO__79809__GEI9n__u39Hv 24s ease infinite;
}
.private_room_main_wrapper.errorClass{
    box-shadow: 0px 3px 4px rgba(255, 3, 41, 0.3);
 
}
#align_self{
    align-self:center;
    justify-self: center;
}
#password_input{
    display: flex;
    position: relative;
    gap:10px;
    width: 80%;
    padding-bottom:25px;
    align-self: center;
    justify-self: center;
}
#eye_ball{
    position: relative;
    top:5px;
}
#submit_button{
    width:100px;
}

@media only screen and (max-width: 600px){
    .private_room_main_wrapper{
        top:100px
    }
}