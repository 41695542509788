.headerContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 9px;
  gap: 5px;
  background-color: black;
  width: auto;
}
.add-hub-icon{
  color: palegreen;
 align-self: center;
 justify-self: center;
  margin-left:5px;
  font-weight: 700px;
}
.create_room_container {
  display: flex;
  flex-direction: row;
  flex-grow: inherit;
  flex-wrap:nowrap;
  gap: 1rem;
  width: auto;
  align-items: center;
}

.logo-font {
  font-family: "Berkshire Swash", serif;
  margin-right: 1rem;
  text-wrap: nowrap;
  padding-bottom: 5px;
}
.header_profile_pic {
  display: flex;
 
  animation: loadIn 0.9s ease-in-out;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border-radius:15px;
}
.header_profile_pic:hover {
  transform: scale(1.04);
  cursor:pointer;
  box-sizing: border-box;
}
.logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: center; */
  padding-left: 10px;
}
.menu_header_wrapper {
  display: inline-flex;
  align-items: center;
 
  color: white;
  height: 10%;

  font-size: 25px;
  background-color: transparent;
  border:none;
  box-sizing: border-box;
  transition: transform .2s ease-in-out;
}
.menu_header_wrapper:hover{
  transform:scale(1.5);
  flex:1;
  cursor: pointer;
}
.logo_and_menu_button_wrapper {
  display: flex;
  align-items: center;
  gap:10px;
  justify-content: center;
}


@keyframes loadIn {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0);
  }
}

#logout-bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 19px;
  padding-right: 10px;
  width: auto;
}
.logo-font a {
  text-decoration: none;
  color: inherit; /* Or set to any color you like */
}
.logo-font:hover {
  color: red;
  cursor: pointer;
  pointer-events: stroke;
}
.logout_wrapper {
  display: flex;
  gap: 25px;
}
.buttonHeader {
  animation: slideFromTop 1s ease-out 0s 1;
  display: flex;
  background-color: transparent;
  text-align: center;
  color: white;
  border: 2px solid rgb(12, 144, 237);
  border-radius: 100px;
  padding: 3px 15px;
  width: auto;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 800;
}
@keyframes slideFromTop {
  0% {
    transform: rotateX(180deg);
  }
  25% {
    transform: translateY(-100%);
  }
  30% {
    transform: translateX(0);
  }
  40% {
    transform: rotateX(45deg);
  }
  50% {
    transform: translateY(-50%);
  }
  60% {
    transform: translateY(-45%);
  }
  70% {
    transform: translateX(0);
  }
  80% {
    transform: translateY(-28%);
  }
  90% {
    transform: translateX(0);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

.buttonHeader:hover {
  background-position: 100% 100%;
  transform: scale(1.05);
}
#logout_button {
  background-color: transparent;
  height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 8px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 800;
  &:hover {
    background-color: white;
    color: black;
    border: 2px inset black;
  }
}

.navLinks {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  
}
.navLinks > li {
  
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0px;
  height: 20px;
}
@keyframes slideFromLeft {

0%{
  transform: translateY(300px);
}
  100% {
   transform: translateY(0);
  }
}
.navLinks a {
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  justify-content: center;
  text-decoration: none;
  height: inherit;
}

@keyframes slideFromTop {
  0% {
    transform: rotateX(180deg);
  }
  25% {
    transform: translateY(-100%);
  }
  30% {
    transform: translateX(0);
  }
  40% {
    transform: rotateX(45deg);
  }
  50% {
    transform: translateY(-50%);
  }
  60% {
    transform: translateY(-45%);
  }
  70% {
    transform: translateX(0);
  }
  80% {
    transform: translateY(-28%);
  }
  90% {
    transform: translateX(0);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateX(0);
  }
}
.text_field_parent{
  display: flex;
  align-items: center;
  justify-content: flex-start;
   max-width: 159px;
  min-width: 100px;
  align-items: flex-start;
  z-index: 1;
}
#textFieldIcon{
  position: absolute;
  color:black;
  z-index: 3;
}
.roomInput {
  position: relative;
  margin: 0;
  border-radius: 7px;
  height: 25px;
  padding-left:30px;
  align-self: center;
  
  z-index: 2;
}

.visible_login_success {
  position: absolute;
  top: 90vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 25px;
  border: 1px solid white;
  border-radius: 10px;
  padding: 10px;
  font-size: 10px;
}
.visible_login_success > #loggedIn {
  color: #49c219;
}
.linkInactive {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: rgb(255, 255, 255);
  font-size: 22px;
  white-space: nowrap;
  min-width: -moz-fit-content;
  min-width: fit-content;
  text-overflow: ellipsis;
  transition: transform .2s ease-in
}
.linkActive {
  display: flex;
  white-space: nowrap;
   align-items: center;
  justify-content: center;
  min-width: fit-content;
  text-overflow: ellipsis;
  color:#4BE227;

  &:hover {
   align-self: center;
   justify-self: center;
    scale: 1;
  }
}
.linkInactive:hover {
  color: rgb(248, 248, 248);
  transform: scale(1.09)
}

@media only screen and (max-width: 600px) {
  .headerContainer {
    flex-direction: column;
    min-width: 92%;
    height: -moz-fit-content;
    height: auto;
    margin: 0px;
    border-radius: 10px;
    /* padding: 7px; */
   
    align-items: flex-start;
    justify-content: flex-start;
  }
  .headerContainer.home {
    flex-direction: row;
    min-width: 92%;
    max-height: 50px;
    height: fit-content;
    margin: 0px;
    border-radius: 12px;
    border: 2px ridge silver;
    align-items: center;
       justify-content: center;
  }

  .create_room_container {
    animation: slideFromTop 1s ease-out 0s 1;
    flex-direction: column;
    display: flex;
    position: relative;
    width: 75vw;
    gap: 0.5rem;
    max-width: 22rem rem;
    /* min-width: auto; */
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(75, 75, 75, 0.7));
    border-top: 1px solid rgb(50, 45, 45);
    border-left: 1px solid rgb(50, 45, 45);
    padding-bottom: 5px;
    border-radius: 20px;
    align-self: center;
    justify-self: center;
    z-index: 100;
  }
  @keyframes slideFromTop {
    0% {
      transform: rotateX(180deg);
    }
    25% {
      transform: translateY(-100%);
    }
    30% {
      transform: translateX(0);
    }
    40% {
      transform: rotateX(45deg);
    }
    50% {
      transform: translateY(-50%);
    }
    60% {
      transform: translateY(-45%);
    }
    70% {
      transform: translateX(0);
    }
    80% {
      transform: translateY(-28%);
    }
    90% {
      transform: translateX(0);
    }
    95% {
      transform: translateY(-2%);
    }
    100% {
      transform: translateX(0);
    }
  }
  .create_room_container > h2 {
    padding: 1px;
    height: 10px;
    text-wrap: wrap;
  }

  #logout_button:hover {
    background-position: 100% 100%;
  }
  .roomInput {
    width: 75%;
   
    font-family: "Shantell Sans", cursive;
  }
  .header_profile_pic {
    display: flex;
    align-self: center;

    animation: loadIn 0.9s ease-in-out;
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }
  .navLinks {
   
    flex-direction: column;
    position: relative;
    gap: 0px;
    width: auto;
    left: -38px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0px;
    margin-bottom: -16px;
  }

  #logout-bottom-container {
    position: absolute;
    display: inline;
    top: 16px;
    right: 10px;
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
    z-index: 1;
  }
  .linkActive {
    animation: 0.4s ease 0s 1 slideLeftRight;
    transform: translateX(50px);
    border: 1px ridge rgb(8, 8, 8);
    border-radius: 0px 15px 15px 0px;
    padding: 4px;
    border-left: none;
    padding-right: 19px;
    box-sizing: border-box;
    background: linear-gradient(
      to right,
      rgba(3, 3, 3, 0.7),
      rgba(94, 94, 90, 0.719)
    );
  }
  @keyframes slideLeftRight {
    0% {
      transform: translateX(-15%);
    }

    100% {
      transform: translateX(50px);
    }
  }
  .buttonHeader {
    min-width: 100px;
    padding: 5px 10px;
    height: 36px;
  }
  .logo-wrapper {
    position: relative;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 10px;
  }
  
  .logo-font {
    position: relative;
    display: flex;
    width: fit-content;
    text-wrap: nowrap;
    font-size: 24px;
  }
  .logo_and_menu_button_wrapper{
    gap:0px;
    margin-left:-10px;
  }
  .visible_login_success {
    top: 80%;
    right: 10px;
    width: fit-content;
    max-height: 0px;
    gap: 2px;
    background-color: gray;
    gap: 5px;
    background-color: transparent;
  }
  .visible_login_success > p {
    font-size: 15px;
  }
  a > h2 {
    font-size: 20px;
  }
  ul > li {
    height: 10%;
    font-size: 1rem;
  }

  #span-id {
    display: none;
  }

  .server-selection {
    display: flex;
    width: 50%;
    height: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .mainAccessBtn {
    width: 100%;
    display: flex;
    border: 3px outset black;
    color: white;
    align-items: center;
    align-self: center;
    text-align: center;
    justify-content: center;
    background-color: black;
    border-radius: 10px;
  }
  .header_profile_pic{
    height:45px;
    width: 45px;
  }
}
