.flex_dots {
    position: relative;
    min-width: 160px;
    overflow: hidden;
    flex-wrap: nowrap;
  display: flex;

  color: white;
  box-sizing: border-box;
}
p{
    margin:0;
}
#dots{
    white-space: nowrap;
    flex-wrap: nowrap;
}
:is(p) {
  text-transform: capitalize;
  font-family: sans-serif;
  font-weight: 800;
}
:is(em) {
  color: rgb(10, 203, 233);
  text-transform: lowercase;
}
:is(#dots) {
  color: rgb(253, 250, 250);
  font-weight: 800;
}
