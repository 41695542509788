.profile_main_container {
 position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 99vw;
    /* min-height: 35vw; */
    /* max-height: 35vw; */
    margin-left: 1px;
    margin-top: -2rem;
    margin-bottom: -52px;
    padding-top: 3rem;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
}
#password-span{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left:-25px;
  gap:10px;
}
.profile_content {
position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    outline-offset: 1rem;
    gap: 20px;
    z-index: 200;
}
.profile_form_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: rgb(0, 0, 0);
  height: fit-content;
  width: 90vw;
  z-index: 159;
}
form {
  animation: dropDown .5s ease;
  position: relative !important;
  display: flex !important;
  border-radius: 20px;
  flex-direction: column;
    flex-direction: column;
    background: linear-gradient( 135deg, #1b2e3d 0%, #273747 25%, #2a4256 50%, #20272d 100% ) !important;
  align-items: flex-start;
  justify-content: space-around;
  height: fit-content;
  margin-bottom:100px;
  width: 100%;
  transition: transform 1s ease-in-out;
}
@keyframes dropDown {
  0%{
    transform:translateY(-300%)
  }
  100%{
    transform:translateY(0)
  }
}
textarea {
  position: relative;
  width: 56vw;
  height: auto;
  padding: 10px;
  font-family: "Varela Round", sans-serif;
  margin-bottom: 20px;
  perspective: 1000px;
  gap: 10%;
  color: white;

  /* Adding styling to match the chat room */
  background: black !important;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);

  /* Optional: 3D border to add a pop */
  border: 2px inset #000000;
  border-top: 3px outset #2d4256;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 10px;
  resize: none; /* This property disables textarea resizing */
}
textarea::placeholder {
  color: rgba(191, 187, 187, 0.603);
  font-family: "Varela Round", sans-serif;
}
.about_me_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  padding: 60px;
}
#aboutme {
  margin: 0 auto;
  padding: 29px;
  width: 42vw;
  color: white;
  background-color: #060606;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
#spanIcons {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
label {
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.about_me {
  margin: 0 auto;
  padding: 20px;
  color: white;
  background-color: transparent;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}
#about_me_button {
 position: relative;
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    justify-self: center;
    box-sizing: border-box;
    gap: 5px;
    background-color: transparent;
    border: 1px solid rgb(52 126 156);
    border-radius: 6px;
    color: #ffffff;
    font-weight: 600;
    padding: 3px 15px;
    min-width: 150px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    text-align: center;
    justify-content: center;
    z-index: 3;
}
#about_me_button:hover {
  background-position: 100% 100%;
  border-color: #18db18;
  transform: scale(1.2);
  border-width: 3px;
}
p {
  color: white;
}
.profile_content > h2 {
  align-self: flex-start;
}
.regular_border {
  position: relative;

  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.occupation_education {
  background: linear-gradient(160deg, #23262a, #1a1e20, #202327, #1e2327);
  border-top: 2px inset #1f262d;
  border-left: 2px inset #1f262d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    0 1px 1px rgba(52, 51, 51, 0.7), 0 2px 2px rgba(55, 53, 53, 0.2),
    0 4px 4px rgba(0, 0, 0, 0.2), 0 8px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
      width: 28%;
  gap: 10px;
  border-radius: 15px;
  padding: 15px;
}
.labelInputs {
  font-size: 10px;
}
.proffessionInput {
  height: 20px;
}
.proffessionInput > input {
  padding-left: 10px;
}
.occupation_education_input {
  height: 10px;
}
#profile_pic {
  position: relative;
  border-radius: 100px;
  width: 15rem;
  border-right: 6px solid black;
  border-left: 6px solid black;
  box-shadow: 0px 5px 5px 2px rgb(15, 14, 14);
  box-sizing: border-box;
}
.educationSection {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.wrapper {
  position: relative;
  height: 16rem;
}

.gradient_border {
  --border-width: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  font-family: Lato, sans-serif;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: white;
  background: #222;
  border-radius: var(--border-width);
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      60deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 4s alternate infinite;
  }
}
.profile_wrapper_image_icon{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #1f7e9b;
  align-items: center;
  width:30vw;
  border-radius: 25px;
  padding-bottom:25px;
  padding-top:25px;
}
#switchIcon{
margin-bottom: 10px;
color:white;
}
#switchIcon:hover{
  transform:scale(1.5)
}

#switchIcon_add{
  animation: addFriend .4s ease-out;
  position: relative;
  align-self: center;
  justify-self: center;
  
color:white;
transition: transform .3s ease-in
}
#switchIcon_add:hover{

cursor: pointer;
outline:4px transparent black;
box-sizing: border-box;
border-radius: 8px;
background-color: transparent;

}
.friend_wrapper.remove{
position: relative;
    display: flex;
    gap: 10px;
    height: 45px;
    padding: 15px;
    overflow: hidden;
    background-color: #3983a1;
    border-radius: 18px;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    min-width: 100px;
    border: 3px groove rgb(60 172 196);
    transition:  transform 1s ease-in-out,border-radius .3s ease-in-out, min-width .5s ease-out;
}
.friend_wrapper{
  
position: relative;
    display: flex;
    gap: 10px;
    height: 45px;
    padding: 15px;
    overflow: hidden;
    background-color: #3983a1;
    border-radius: 18px;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    min-width: 100px;
    border: 3px groove rgb(60 172 196);
    transition:  transform 1s ease-in-out,border-radius .3s ease-in-out, min-width .5s ease-out;
  
}
.friend_wrapper:hover{
 
  border-radius:2px;
  cursor:pointer;
}
@keyframes unfriend {
  0%{
    transform:translateX(-300%)
  }
  100%{
    transform:translateX(0)
  }
}
  @keyframes addFriend{ 
  0%{
    transform:translateX(300%)
  }
  100%{
    transform:translateX(0)
  }
}
#switchIcon_remove{
  animation: unfriend .4s ease-out;
  position: relative;
  align-self: center;
  justify-self: center;
 
color:rgb(210, 79, 79);
transition: transform .3s ease-in
}
#switchIcon_remove:hover{

cursor: pointer;
outline:4px transparent black;
box-sizing: border-box;
border-radius: 8px;
background-color: transparent;

}
#username_switch_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.is_editing_dots {
  position: absolute;
}
#button_svg_toggle {
  position: relative;
  height: 50px;
  width: 50px;
  opacity: 25%;
  z-index: 1;
  transform: rotate(90deg);
 
}
#button_svg_toggle:hover {
 
  
  opacity: 100%;
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

@media only screen and (max-width: 600px) {
  .profile_main_container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  }
  #button_svg_toggle {
  opacity: 50%;
  }
  form {
    position: relative !important;
    width: 92vw;
  }
  .profile_wrapper_image_icon{
    width:70vw;
    padding:20px 10px;
  }
  textarea {
    position: relative;
   width: 78vw;
    height: auto;
  }
  .profile_content {
    flex-direction: column;
    gap: 0px;
    align-items: center;
  }
  .profile_content > h2 {
    align-self: center;
  }
  #username_switch-wrapper {
    align-self: center;
    
  }
  .regular_border {
    align-self: center;
  }
  .occupation_education {
    width: 258px;
    padding: 19px;
    align-items: center;
  }
}
