.rooms_wrapper {
  position: relative;
  max-width: 95vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px 0px;
  gap: 25px;
  background: linear-gradient(
    135deg,
    #161a1d 0%,
    #283644 25%,
    #1e252b 50%,
    #161a1d 100%
  );
  height: auto;
  min-width: 80vw;
  animation: gradientBackground 24s ease infinite;
  padding: 35px;
  padding-bottom: 10px;
  padding-top: 55px;
  border: 2px solid #1c2125;
  border-top: 4px inset #1f262d;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(22, 108, 150, 0.3);
  z-index: 1;
}
#grid_view_icon {
  position: absolute;
  top: 8px;
  right: 20px;
  color: white;
  font-size: 25px;
  background-color: transparent;
  z-index: 101;
}
#grid_view_icon:hover {
  transform: scale(1.15);
}
.grid_view_wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: -moz-fit-content;
  max-width: 750px;
  min-width: 24vw;
  max-height: 95vh;
  flex-wrap: wrap;
  padding: 15px;
  align-items: center;
  justify-content: center;
  margin: 8x 0px;
  gap: 36px;
  background: linear-gradient(
    135deg,
    #161a1d 0%,
    #283644 25%,
    #1e252b 50%,
    #161a1d 100%
  );

  padding: 48px;
  padding-top: 40px;
  border: 2px solid #1c2125;
  border-top: 4px inset #1f262d;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(22, 108, 150, 0.3);
  overflow: scroll;
  scroll-behavior: auto;
  z-index: 3;
}

#icon_left_right {
  color: white;
  &:hover {
    transform: scale(1.5);
  }
}
#no_data_info {
  text-align: center;
  color: white;
}
.flex {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex_row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding-top: 10px;
  align-self: center;
}

#display_created_room_name {
  position: absolute;
  display: flex;
  top: -34px;
  left: -5px;
  align-self: flex-start;
  color: white;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  border-radius: 16px 16px 1px 2px;
  font-size: 20px;
  margin-left: 20px;
  padding: 5px 10px;

  border: 1px outset #000000;
  background: linear-gradient(160deg, #282b30, #1d2123, #262a2e, #15181b);
}
#all_rooms_room_name {
  position: absolute;
  display: flex;
  top: -39px;
  left: 25px;
  min-width: 350px;
  align-self: flex-start;
  color: white;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  border-radius: 16px 16px 1px 2px;
  font-size: 20px;
  margin-left: 20px;
  padding: 5px 10px;
  border: 1px outset #000000;
  background: linear-gradient(160deg, #282b30, #1d2123, #262a2e, #15181b);
}
.room_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 50vw;
}

#room_count {
  color: white;
  box-sizing: border-box;
  font-family: "Shantell Sans", cursive;
  font-size: 14px;
  font-weight: 800;
}

.room_item_wrapper {
 position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80vw;
    max-width: 90%;
    gap: 45px;
    overflow: scroll;
    padding: 25px;
    border-radius: 24px;
    background: linear-gradient(160deg, #23262a, #1a1e20, #202327, #1e2327);
    /* border: 1px solid #1c1f22; */
    border: -5px solid #1c2125;
    /* border-bottom: 2px outset #1f262d; */
    /* border: 2px solid #1c2125; */
    border-top: 4px inset #1f262d;
    border-left: 2px inset #1f262d;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 1px rgba(52, 51, 51, 0.7), 0 2px 2px rgba(55, 53, 53, 0.2), 0 4px 4px rgba(0, 0, 0, 0.2), 0 8px 8px rgba(0, 0, 0, 0.2);
    z-index: 2;
    transition: min-width width height max-width 1s ease-in-out;
}
.room_item_subscribe {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7em;
  height: 7em;
  box-sizing: border-box;
  border-radius: 10px;

  background: linear-gradient(
    135deg,
    #145a32 0%,
    #1f8b4c 25%,
    #145a32 50%,
    #145a32 100%
  );
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4), 0 12px 28px rgba(0, 0, 0, 0.6),
    0 20px 50px rgba(0, 0, 0, 0.8);

  background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.1) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0.1) 75%,
      transparent 75%,
      transparent
    ),
    linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0.1) 75%,
      transparent 75%,
      transparent
    ),
    linear-gradient(
      135deg,
      #000000 0%,
      #000000 25%,
      #e74c3c 25%,
      #e74c3c 50%,
      #3498db 50%,
      #3498db 100%
    );
  background-size: 40px 40px, 40px 40px, 100% 100%;
  transition: all 0.3s ease-in-out;
  z-index: 3;
}

.room_item_subscribe:hover {
  border-radius: 32px;
  cursor: default;
  border: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5), 0 6px 12px rgba(0, 0, 0, 0.5),
    0 10px 20px rgba(0, 0, 0, 0.5), inset 0 -8px 16px #0d1115,
    inset 0 8px 16px #1c2125;
  transition: all 0.3s ease-in-out;
}
.room_item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7em;
  height: 7em;
  box-sizing: border-box;
  border-radius: 10px;
  border: 2px solid rgb(23, 112, 60);
  background: linear-gradient(
    135deg,
    #145a32 0%,
    #1f8b4c 25%,
    #145a32 50%,
    #145a32 100%
  );
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4), 0 12px 28px rgba(0, 0, 0, 0.6),
    0 20px 50px rgba(0, 0, 0, 0.8);

  background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.1) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0.1) 75%,
      transparent 75%,
      transparent
    ),
    linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0.1) 75%,
      transparent 75%,
      transparent
    ),
    linear-gradient(
      135deg,
      #145a32 0%,
      #145a32 25%,
      #1f6f43 25%,
      #185734 50%,
      #145230 50%,
      #0a3f23 100%
    );
  background-size: 40px 40px, 40px 40px, 100% 100%;
  transition: all 0.3s ease-in-out;
  z-index: 3;
}

.room_item.room_public_private_green::before {
  content: "\1F512";
  font-size: 2em;
  color: #ecf0f1;
  position: absolute;
}
.room_item:hover {
  border-radius: 32px;
  cursor: default;
  border: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5), 0 6px 12px rgba(0, 0, 0, 0.5),
    0 10px 20px rgba(0, 0, 0, 0.5), inset 0 -8px 16px #0d1115,
    inset 0 8px 16px #1c2125;
  transition: all 0.3s ease-in-out;
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0) rotate(360deg);
  }
  to {
    transform: translateX(-1000%);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(800%) rotate(360deg);
  }
  100% {
    transform: translateX(0) rotate(0) translateY(0);
  }
}

.slideOut {
  animation: slideOutLeft 600ms forwards;
}

.slideIn {
  animation: slideInRight 800ms forwards;
}
.dropdown_list:hover {
  background-color: rgba(0, 0, 0, 0.182);
}
.room_item.room_private {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7em;
    height: 7em;
    box-sizing: border-box;
    border-radius: 10px;
    border: 2px solid #0a294d;
    background: linear-gradient( 135deg, #192a56 0%, #3f5cac 25%, #192a56 50%, #03060e 100% );
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4), 0 12px 28px rgba(0, 0, 0, 0.6), 0 20px 50px rgba(0, 0, 0, 0.8);
    background-image: linear-gradient( 45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent ), linear-gradient( 135deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent ), linear-gradient( 135deg, #192a56 0%, #192a56 25%, #2e7eb4 50%, #245a7e 100% );
    background-size: 40px 40px, 40px 40px, 100% 100%;
    transition: all 0.3s ease-in-out;
    z-index: 3;
}
.room_item.room_private,
.room_item.room_public_private {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7em;
  height: 7em;
  box-sizing: border-box;
  border-radius: 10px;
  border: 2px solid #012248;
  background: linear-gradient(
    135deg,
    #192a56 0%,
    #273c75 25%,
    #192a56 50%,
    #192a56 100%
  );
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4), 0 12px 28px rgba(0, 0, 0, 0.6),
    0 20px 50px rgba(0, 0, 0, 0.8);

  background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.1) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0.1) 75%,
      transparent 75%,
      transparent
    ),
    linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0.1) 75%,
      transparent 75%,
      transparent
    ),
    linear-gradient(
      135deg,
      #192a56 0%,
      #192a56 25%,

      #2e7eb4 50%,
      #245a7e 100%
    );
  background-size: 40px 40px, 40px 40px, 100% 100%;
  transition: all 0.3s ease-in-out;
  z-index: 3;
}

.room_item.room_public_private::before {
  content: "\1F512";
  font-size: 2em;
  color: #ecf0f1;
  position: absolute;
}
.room_item.room_private:hover {
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5), 0 6px 12px rgba(0, 0, 0, 0.5),
    0 10px 20px rgba(0, 0, 0, 0.5), inset 0 -8px 16px #0d1115,
    inset 0 8px 16px #1c2125;
  border: none;
  transition: all 0.2s ease-in-out;
}
#room_owned_by_img {
  position: absolute;
  border-radius: 50px;
  max-height: 35px;
  min-width: 30px;
  top: -10px;
  left: -10px;
}
#private_rooms_visibility_icon {
  display: flex;
  padding-right: 10px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  color: white;
}
#room_owned_by_img_expolore {
  position: absolute;
  display: flex;
  border-radius: 50px;
  height: 35%;
  width: 35%;
  top: -5px;
  left: -7px;
}
.css-i4bv87-MuiSvgIcon-root {
  font-size: 50px;
}
.check_mark {
  position: absolute;
  display: flex;
  border-radius: 50px;
  top: 1px;
  left: -10px;
  color: green;
  background-color: transparent;
}
#checked {
  font-size: 36px;
  position: absolute;
  fill: rgb(20, 226, 58);
  top: -4px;
  left: 7px;
}
.display_joined_list {
  animation: roomMembers 1.5s ease;
  position: absolute;
  display: flex;
  bottom: -2px;
  z-index: 39;

  background-color: rgba(255, 255, 255, 0.271);
  padding: 2px;
  right: 1px;
  border-radius: 50px;
  color: rgb(0, 0, 0);
  overflow: hidden;
}
@keyframes roomMembers {
  0% {
    transform: translateY(-100px);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: translateY(0px);
  }
}
.display_joined_list:hover {
  transform: scale(1.15);
  background-color: #000000;
  color: white;
  cursor: pointer;
}
.room_members {
  animation: dropUp 0.5s ease-in-out;
  position: relative;
  display: flex;
  font-size: 95%;
  flex-direction: column;
  align-items: center;
  color: white;
  display: flex;
  text-align: center;
  align-self: center;
  z-index: -1;
}
.room_members._active {
  color: white;
}
.parent_of_diplayed_room_members {
  animation: dropDown 0.6s ease-in-out;
  position: relative;
  height: 101%;
  width: 101%;
  border-radius: inherit;
  background-color: black;
  display: flex;
  font-size: 95%;
  flex-direction: column;
  align-items: center;
  gap:8px;
  justify-content: center;
  color: white;
  display: flex;
  text-align: center;
  align-self: center;
  z-index: -1;
}

#child_of_room_members {
  position: relative;
  display: flex;
  max-height: 1px;
  min-height: 1px;
  top: -15px;
}
#number_span {
  position: relative;
  font-weight: 700;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: rgb(255, 217, 0);
  background-color: black;
  border: 2px solid black;

  padding: 5px;
  width: fit-content;
}
@keyframes dropDown {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0);
  }
  65% {
    transform: translateY(-12%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes dropUp {
  0% {
    transform: translateY(150%);
  }
  50% {
    transform: translateY(0);
  }
  65% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0);
  }
}
.menu_wrapper {
  position: absolute;
  border-radius: 50px;
  height: 35px;
  width: 40px;
  top: -12px;
  right: 10px;
  z-index: 1;
}
.menu_wrapper svg {
  position: relative;
  color: white;
  z-index: 1;
}
#menu_icon {
  position: relative;
  color: rgb(255, 255, 255);
  right: 3px;
  padding: 3px;
  border-radius: 50px;
  background-color: rgba(171, 54, 54, 0.612);
  font-size: 26px;
}
#menu_icon > path {
  position: relative;
  display: flex;
  height: 10px;
  align-self: center;
  justify-self: center;
}
#menu_icon:hover {
  transform: scale(1.15);
}

@media only screen and (max-width: 600px) {
  .rooms_wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95vw;
    gap: 1rem;
  }
  .grid_view_wrapper {
    min-width: 88vw;
    max-height: 37vh;
    padding: 10px;
    padding-top: 39px;
    margin-top: 0px;
  }
  #checked {
    font-size: 22px;
    top: -3px;
  }
  #menu_icon {
    font-size: 100%;
    left: 11px;
    top: 5px;
    font-size: 16px;
  }
  .flex {
    position: relative;
  }
  .flex_row {
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  .room_item_wrapper {
   gap: 16px;
    padding: 15px;
    overflow: scroll;
    align-items: center;
    min-width: 89vw;
    max-width: -17%;
    padding-top: 36px;
    z-index: 5;
  }
  #display_created_room_name {
    font-size: 15px;
    left: -1px;
    border-radius: 16px 16px 1px 2px;
  }
  #all_rooms_room_name {
    font-size: 14px;
    min-width: fit-content;
    flex-wrap: nowrap;
    top: -32px;
    left: 17px;
    border-radius: 16px 16px 1px 2px;
  }
  .room_item.room_public_private {
    height: 4.5em;
    width: 4.5em;
  }
  .room_item.room_private {
    height: 5em;
    width: 5em;
  }
  .room_item {
    height: 5em;
    width: 5em;
  }
  .room_item > p {
    font-size: 10px;
  }
  .room_item_subscribe {
    height: 5em;
    width: 5em;
  }
  #room_owned_by_img {
    position: absolute;
    border-radius: 50px;

    top: -5px;
    left: -8px;
  }
  .display_joined_list {
    bottom: 0px;
    max-width: 18px;
    max-height: 18px;
  }

  #room_owned_by_img_expolore {
    position: absolute;
    border-radius: 50px;
    top: -5px;
    left: 1px;
  }
}
