.room-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 10px 10px;
  border: 4px solid #1c2125; /* Change the border color to match */
  border-top: 4px inset #0d1115; /* Inset color to match the internal shadow of .all-messages */
  box-sizing: border-box;
  background-color: #161a1d;
  animation: gradientBackground 24s ease infinite;
 
  
}
#header_in_current_servers{
  position: absolute;
  top:-3px;
      font-family: "Berkshire Swash", serif;
}
.room-container > h1 {
  color: white;
  align-self: center;
}
.all_hubs_wrapper{
  display: flex;
  flex-direction: column;
  height: auto;
}
.main-room-wrapper {
  display: flex;
  flex-direction: column;

  align-self: center;
  align-items: center;
  
  justify-content: center;
  width: 50%;
}
.main-room-wrapper.new-user {
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 300px;
}
.main-room-wrapper.new-user > button {
  width: 200px;
  align-self: center;
}

.active-rooms-joined {
}


/* Add a pseudo-element for a decorative circle */
.main-room-button {
     position: relative;
    height: auto;
    display: flex;
    gap: 10px;
    color: white;
    padding:5px;
    background: transparent;
    background-size: 200% 200%;
    animation: gradientBackground 24s ease infinite;
    border-radius: 18px;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    top: 10ppx;
    top: 14px;
    justify-content: center;
    min-width:300px;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    text-transform: uppercase;
}
.main-room-button>span{
  color:green;
}
#new_user_server_message{
  position: relative;
  top:20px;
      font-size: 25px;
    color: orangered;
    padding:5px;
    text-align: center;
    border: 3px solid;
    border-radius: 15px;
}
.main-room-button>p{
  font-size: 15px;
}


.main-room-button:hover {
  transform: scale(1.1); /* Slightly scaled down */
  border-radius: 2px; /* Less rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5),
    0 6px 12px rgba(0, 0, 0, 0.5),
    0 8px 15px rgba(0, 0, 0, 0.5);

  transition: all 0.4s ease-in-out; /* Faster transition */
}
.custom-text {
  display: inline-block;
  width: fit-content;
  color: darkolivegreen;
  font-size: 55px; /* Adjust the font size as needed */
  /* Other styles */
}
.room_item_wrapper{
       position: relative;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 10px 0px;
    gap: 25px;
    background: linear-gradient( 135deg, #161a1d 0%, #283644 25%, #1e252b 50%, #161a1d 100% );
    height: auto;
    min-width: 80vw;
    animation: RoomsCreated_gradientBackground__vCXgf 24s ease infinite;
    padding: 35px;
    padding-bottom: 10px;
    padding-top: 55px;
    border: 2px solid #1c2125;
    border-top: 4px inset #1f262d;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 4px 8px rgba(22, 108, 150, 0.3);
    z-index: 1;
}
@media only screen and (max-width: 600px) {
  .room-container > h1 {
    font-size: 20px;
    font-weight: 800;
  }
}
